/*
 * https://www.tints.dev/
 * https://m2.material.io/design/color/the-color-system.html#color-theme-creation
 */

import { Metadata } from 'next'

import { APP_URL, COMPANY } from './constants/env'
import { Theme } from './context/theme/theme.types'

export const name = 'SmartGrants.ai'

export const defaultTheme: Theme = Theme.DARK

export const colors = {
  light: {
    primary: '#0363d2',
    'primary-light': '#a3cdfe',
    'primary-dark': '#02346d', // Not used anywhere yet but part of material design
    secondary: '#ffa439', // primary buttons
    surface: '#eaf3fc',
    background: '#ffffff',
    error: '#b00020', // Not used anywhere yet but part of material design
    warning: '#cd9526',
    on: {
      primary: '#f2f2f2',
      secondary: '#1e1d1d',
      surface: '#1e1d1d',
      background: '#1e1d1d',
      error: '#f2f2f2', // Not used anywhere yet but part of material design
      warning: '#f2f2f2',
    },
  },
  dark: {
    primary: '#0363d2',
    'primary-light': '#a3cdfe',
    'primary-dark': '#02346d', // Not used anywhere yet but part of material design
    secondary: '#ffa439', // primary buttons
    surface: '#0f1b25',
    background: '#312f2f',
    error: '#b00020', // Not used anywhere yet but part of material design
    warning: '#cd9526',
    on: {
      primary: '#f2f2f2',
      secondary: '#1e1d1d',
      surface: '#eaf3fc',
      background: '#ffffff',
      error: '#f2f2f2', // Not used anywhere yet but part of material design
      warning: '#f2f2f2',
    },
  },
  gray: {
    50: '#E6E6E6',
    100: '#D9D9D9',
    200: '#BFBFBF',
    300: '#A6A6A6',
    400: '#8C8C8C',
    500: '#737373',
    600: '#595959',
    700: '#404040',
    800: '#262626',
    900: '#0D0D0D',
    950: '#000000',
  },
}

export const sidebarTitle: string = 'AI'

// DEVELOPMENT: height: 80, top: 'top-[6.5em]'
// PRODUCTION: height: 60, top: 'top-28'
export const logoLoginClx = 'h-[80px] w-auto top-[6.5em]'
export const logoIndexClx = 'h-[42px] w-auto'
export const logoSidebarClx = 'h-[32px] w-auto'

export const poweredBy = {
  text: 'SE7EN',
  url: 'https://www.se7en.si/',
}

export const showIndexHeaderText = true

export const brandMetadata: Metadata = {
  title: `${COMPANY ?? ''}`,
  description: 'Use AI to win business grant funding.',
  manifest: '/icons/site.webmanifest',
  icons: [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      url: '/icons/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      sizes: '32x32',
      url: '/icons/favicon-32x32.png',
      type: 'image/png',
    },
    {
      rel: 'icon',
      sizes: '16x16',
      url: '/icons/favicon-16x16.png',
      type: 'image/png',
    },
    { rel: 'mask-icon', color: '#0363d2', url: '/icons/safari-pinned-tab.svg' },
    { rel: 'shortcut icon', url: '/icons/favicon.ico' },
  ],

  openGraph: {
    type: 'website',
    url: 'https://smartgrants.ai',
    title: 'SmartGrants.ai',
    description: 'Use AI to win business grant funding.',
    siteName: 'SmartGrants.ai',
    images: [
      {
        url: `${APP_URL}/ogImage.png`,
      },
    ],
  },
  twitter: {
    card: 'summary_large_image',
    site: 'https://smartgrants.ai',
    images: `${APP_URL}/ogImage.png`,
  },
}
